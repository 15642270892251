import React from "react"

import Image2 from "../images/monta.png"
import Image3 from "../images/seguridad.png"
import Image4 from "../images/wach.png"


export default function Include() {
    



    return (
        <section className="services section-padding" >
                <div className="container">
                    <div className="row">

                        <h2 className="mb-5 text-center">Nuestros Servicios Incluyen</h2>

                        <div className="col-lg-4 col-12 d-flex p-0 box-2" >
                            <img src={Image3} className="img-fluid services-image" alt="" />
                        </div>

                        <div className="col-lg-4 col-12 p-0">
                            <div className="services-info custom-icon-left paddingText">

                                <h4 className="">Recepción</h4>

                                <p className="mb-4">Recepción e inspección física del empaque de la mercancía recibida y verificación cuantitativa del mismo.</p>

                                {/* <a className="custom-btn custom-bg-dark btn" href="#">Learn More</a> */}
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 d-flex box-1 p-0">
                            <img src={Image2} className="img-fluid services-image" alt="" />
                        </div>

                        <div className="col-lg-4 col-12 p-0 order-lg-0 order-sm-5">

                            <div className="services-info custom-icon-right paddingText order-lg-0 order-sm-2">

                                <h4 className="">Seguro</h4>

                                <p className="mb-4">Póliza de seguro (robo, atraco, incendio, inundación, responsabilidad civil y otros)</p>

                                {/* <a className="custom-btn custom-bg-dark btn" href="#">Learn More</a> */}
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 d-flex box-3 p-0 order-lg-0 order-sm-5">
                            <img src={Image4} className="img-fluid services-image" alt="" />
                        </div>

                        <div className="col-lg-4 col-12 p-0 order-lg-0 order-sm-5">

                            <div className="services-info custom-icon-right paddingText order-lg-0 order-sm-2">

                                <h4 className="">Vigilancia </h4>

                                <p className="mb-5">Vigilancia privada las 24 horas del día y los 365 días del año.</p>
                                <p className="mb-4"></p>

                                {/* <a className="custom-btn custom-bg-dark btn" href="#">Learn More</a> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
    )
}
