import React from "react"

import icono1 from "../images/iconos-05.png"
import icono2 from "../images/iconos-06.png"
import icono3 from "../images/iconos-07.png"
export default function Services() {
    


   
   

    return (
        <section className="our-services" id="section_3">
        <div className="container">
            <div className="espacito" />
            <h2 className="mb-5 text-center">Nosotros</h2>
            <div className="row">
                <div className="col-md-4">
                    <div className="service-item">
                        <div className="icon">
                            <img src={icono1} alt="" />
                        </div>
                        <h4>Misión</h4>
                        <p>Somos una empresa competitiva enfocada al servicio de guarda y custodia de los inventarios de nuestros clientes, a través de un excelente servicio personalizado basado en nuestros años de experiencia en el mercado de almacenes generales de depósito.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="service-item">
                        <div className="icon">
                            <img src={icono2} alt="" />
                        </div>
                        <h4>Visión</h4>
                        <p>Ser vistos como el mejor servidor de Almacén General de  Deposito, proporcionando un servicio excepcional a nuestros clientes mediante la mejora continua  en innovación y desarrollo del talento humano, asegurando la mayor rentabilidad.</p>

                    </div>
                </div>
                <div className="col-md-4">
                    <div className="service-item">
                        <div className="icon">
                            <img src={icono3} alt="" />
                        </div>
                        <h4>Valores</h4>
                        <p>La honestidad, la transparencia, la rectitud, la calidez humana y la responsabilidad con que realizamos nuestras actividades, constituyen las bases para mantener la confianza de nuestros clientes.</p>
                        <br /><br />
                    </div>
                </div>
            </div>
        </div>
    </section>

    )
}
