import React from "react"

import imagen2 from "../images/container550.jpg"
import imagen3 from "../images/llenado.jpg"
import imagen4 from "../images/manodeobra.jpg"
import imagen5 from "../images/slide/emsamble.png"
export default function Services() {
    const [selectedTab, setSelectedTab] = React.useState('1')


    function changeSelect(e) {
        const { name } = e.target
        if (name === 'storage') {
            setSelectedTab('1')
        }
        if (name === 'storage2') {
            setSelectedTab('2')
        }
        if (name === 'storage3') {
            setSelectedTab('3')
        }
        if (name === 'storage4') {
            setSelectedTab('4')
        }
    }


    return (
        <section className="about section-padding" id="section_2">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-12">
                        <h2 className="mb-5">Servicios</h2>
                    </div>

                    <div className="col-lg-6 col-12 ms-lg-auto mb-5 mb-lg-0">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className={selectedTab === '1' ? "nav-link active" : "nav-link"} name='storage' onClick={changeSelect} id="nav-intro-tab" data-bs-toggle="tab" data-bs-target="#nav-intro" type="button" role="tab" aria-controls="nav-intro" aria-selected="true">Almacen</button>

                                <button className={selectedTab === '2' ? "nav-link active" : "nav-link"} name='storage2' onClick={changeSelect} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Inventario</button>

                                <button className={selectedTab === '3' ? "nav-link active" : "nav-link"} name='storage3' onClick={changeSelect} id="nav-faq-tab" data-bs-toggle="tab" data-bs-target="#nav-faq" type="button" role="tab" aria-controls="nav-faq" aria-selected="false">Container</button>
                                <button className={selectedTab === '4' ? "nav-link active" : "nav-link"} name='storage4' onClick={changeSelect} id="nav-faq-tab" data-bs-toggle="tab" data-bs-target="#nav-faq" type="button" role="tab" aria-controls="nav-faq" aria-selected="false">Ensamble</button>
                            </div>
                        </nav>
                    </div>

                    <div className="col-12">
                        <div className="tab-content" id="nav-tabContent">
                            <div className={selectedTab === '1' ? "tab-pane fade show active" : "tab-pane fade"} id="nav-intro" role="tabpanel" aria-labelledby="nav-intro-tab">
                                <div className="row">
                                    <div className="col-lg-6 col-12 mb-lg-0 mb-4">
                                        <img src={imagen2} className="img-fluid" alt="" />
                                    </div>

                                    <div className="col-lg-5 col-12 m-auto">
                                        <h3 className="mb-3">Almacén General de Depósito</h3>

                                        <p>Permite almacenar, conservar, manipular, controlar, distribuir o comercializar los bienes sujetos a derecho  según  lo  establece  la ley que los regula (Ley de Almacén General de Depósito AGD).</p>



                                    </div>
                                </div>
                            </div>

                            <div className={selectedTab === '2' ? "tab-pane fade show active" : "tab-pane fade"} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div className="row">
                                    <div className="col-lg-5 col-12 m-auto">
                                        <h3 className="mb-3">Control de inventario</h3>

                                        <p>Administracíon y control del estado de la mercancía llevada en el almacen. Recopilando informacíon en la entrada y salida de los productos</p>


                                    </div>

                                    <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                                        <img src={imagen4} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                           
                            <div className={selectedTab === '3' ? "tab-pane fade show active" : "tab-pane fade"} id="nav-faq" role="tabpanel" aria-labelledby="nav-faq-tab">
                                <div className="row">
                                    <div className="col-lg-6 col-12 mb-lg-0 mb-4">
                                        <img src={imagen3} className="img-fluid" alt="" />
                                    </div>

                                    <div className="col-lg-5 col-12 m-auto">
                                        <h3 className="mb-3">Llenado de Container</h3>

                                        <p>Podrán realizar el llenado de container en nuestros patios</p>
                                        <p>La mercancía objeto de exportación se encuentra bajo la custodia y almacenamiento de Almacenadora El Recreo.
                                            Cero “0” costos de traslado a otro recinto para los efectos de llenado y control.</p>


                                    </div>
                                </div>
                            </div>

                            <div className={selectedTab === '4' ? "tab-pane fade show active" : "tab-pane fade"} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div className="row">
                                    <div className="col-lg-5 col-12 m-auto">
                                        <h3 className="mb-3">Linea de ensamble</h3>

                                        <p>Contamos con estaciones de trabajo para el ensamble y examinacíon de distintios productos </p>


                                    </div>

                                    <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                                        <img src={imagen5} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
