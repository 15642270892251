import React from "react"
import Image from "./images/LogoAlma.png"



import Services from "./componets/Services"
import Include from "./componets/Include"
import Us from "./componets/Us"
import Informate from "./componets/Informate"
import FooterCustom from "./componets/FooterCustom"
export default function App() {
    const [viewtab, setviewtab] = React.useState('navbar fixed-top navbar-expand-lg')


    React.useEffect(() => {
        const handleScroll = event => {
            if (window.scrollY >= 50) {
                setviewtab('navbar fixed-top navbar-expand-lg sticky-nav')
            } else {
                setviewtab('navbar fixed-top navbar-expand-lg')
            }
        };


        window.addEventListener('scroll', handleScroll);


    }, []);






    return (


        <main>
            <nav className={viewtab}>
                <div className="container">

                    <a href="index.html" className="navbar-brand">
                        <img src={Image} alt="" width="60px"></img> Almacenadora el Recreo

                    </a>



                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-lg-5">


                            <li className="nav-item">
                                <a className="nav-link" href="#section_1"> </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#section_1"> </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#section_1">Inicio</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#section_2">Servicios</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#section_3">Nosotros</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#section_4">Contactanos</a>
                            </li>

                        </ul>


                    </div>
                </div >
            </nav>


            <Informate />
            <Services />
            <Include />
            <Us />


            <section className="contact" id="contact">


            </section>















            <section className="section-padding" id="section_4">


                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-md-10 col-12 mx-auto">
                            <h2 className="mb-4 text-center">Contáctanos</h2>

                            

                            <form className="custom-form" action="https://formsubmit.co/atencliente@almarecreo.com" method="POST">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12 my-2">
                                        <label className="mb-2" htmlFor="name">Nombre</label>
                                        <input type="hidden" name="_subject" value='Contacto Sitio Web' />
                                        <input type="text" name="name" id="name" className="form-control" required />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 my-2">
                                        <label className="mb-2" htmlFor="email">Correo Electronico</label>
                                        
                                        <input type="hidden" name="_next" value="https://www.almarecreo.com/"></input>
                                        <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control" required />
                                    </div>
                                    <div className="col-12 my-2">
                                        <label className="mb-2" htmlFor="message">Mensaje</label>

                                        <textarea className="form-control" rows="5" id="message" name="message" required></textarea>

                                        <button type="submit" className="form-control mt-4" id="submit">Enviar</button>
                                    </div>

                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <br /><br />

            </section>
            <div id="map" >


                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3927.617046583622!2d-67.95337768571723!3d10.1303989735562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8068a06b92002f%3A0x423880f873aa311e!2sAlmacenadora%20El%20Recreo%2C%20c.a.!5e0!3m2!1ses!2sve!4v1661781939313!5m2!1ses!2sve" width="100%" height="500px" frameBorder="0"></iframe>
            </div>
            <FooterCustom />



        </main>

    )
}