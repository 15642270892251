import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/bootstrap.min.css';
import './css/bootstrap-icons.css';
import './css/magnific-popup.css';
import './css/tooplate-tween-agency.css';
import './css/templatemo-style.css';
import App from "./App"



// ReactDOM.createRoot(<App />, document.getElementById("root"))


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
//   <React.StrictMode>
    <App />
//   </React.StrictMode>
);

// reportWebVitals();