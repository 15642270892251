    import React from "react"

    export default function Informate() {

        // const [currenctback, setCurrentback] = React.useState("a")
        // const [backclass, setBackclass] = React.useState('overlay overlay_back_1')
        // const MINUTE_MS = 6000;




        // React.useEffect(() => {
        //     const interval = setInterval(() => {
        //        console.log(currenctback)
        //         if(currenctback == "a"){
        //             console.log("aaa")
        //             setCurrentback("b")
        //             setBackclass('overlay overlay_back_2')
        //         }
        //         if(currenctback == "b"){
        //             console.log("bbbbb")
        //             setCurrentback("a")
        //             setBackclass('overlay overlay_back_1')
        //         }
        //     }, MINUTE_MS);

        //     return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        // }, [])


        return (
            <section className="hero d-flex justify-content-center align-items-center" id="section_1">
                
                
                
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12">
                            <div className="heroText">
                                <h1 className="text-white mb-lg-5 mb-4">Almacen General de Deposito</h1>

                                <a href="#section_4" className="popup-youtube play-icon-wrap d-flex align-items-center">
                                    <i className="bi-person-fill play-icon"></i>
                                    {/* <img width='40px' src={icono3} className="img-fluid" alt="" /> */}
                                   

                                        Contactanos
                                   
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='overlay'></div>
            </section>
        )
    }
