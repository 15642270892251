import React from "react"

export default function FooterCustom() {



    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">

                    <div className="col-lg-5 col-12 me-auto mb-4">
                        <h5 className="text-white mb-3">Dirección</h5>

                        <ul className="footer-menu">
                            <li className="footer-menu-item"><a href='https://goo.gl/maps/FGTxQxzMUHThbPWU8' className="footer-menu-link">Zona Industrial El Recreo, 1ra. Avenida C/calle “F”, parcela I-69, Flor Amarillo  Valencia</a></li>

                        </ul>
                        <h5 className="text-white mb-6">Horario de Oficina</h5>
                        <ul className="footer-menu">
                            <li className="footer-menu-item"><a className="footer-menu-link">8 am - 4:00 pm de Lunes a Viernes</a></li>

                        </ul>
                    </div>

                    <div className="col-lg-2 col-12 mx-auto my-lg-0 my-4">
                        <h5 className="text-white mb-6">Correo</h5>


                        <ul className="footer-menu">
                            <li className="footer-menu-item"><a className="footer-menu-link">atencliente@almarecreo.com</a></li>

                        </ul>
                        <h5 className="text-white mb-6">Teléfono</h5>
                        <ul className="footer-menu">
                            <li className="footer-menu-item"><a className="footer-menu-link">0414-4329346</a></li>

                        </ul>
                    </div>

       

                    <div className="site-footer-bottom mt-5">
                        <div className="row pt-4">
                            <div className="col-lg-6 col-12">
                                <p className="copyright-text tooplate-link">Copyright © 2022 Almacenadora el Recreo.
                                </p>
                            </div>

                            <div className="col-lg-3 col-12 ms-auto">
                                <ul className="social-icon">

                                    

                                    <li><a href="https://www.instagram.com/almacenadorarecreo/" className="social-icon-link bi-instagram"> </a></li>
                                    <li><a href="https://www.facebook.com/ALMARECREO" className="social-icon-link bi-facebook"> </a></li>

                                    <li><a href="https://www.linkedin.com/in/almacenadora-el-recreo-c-a-07458697/" className="social-icon-link bi-linkedin"> </a></li>


                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    )
}
